module.exports = {
  author: 'Joe Kupres',
  siteTitle: 'Mia Kupres - official website', // <title>
  shortSiteTitle: 'Mia Kupres - official website', // <title> ending for posts and pages
  siteDescription: 'Mia Kupres - official website.',
  siteUrl: 'https://miakupres.com',
  // pathPrefix: "",
  siteImage: 'preview.jpg',
  siteLanguage: 'en',

  /* author */
  authorName: 'Joe Kupres',
  authorTwitterAccount: 'miakupres',

  /* info */
  headerTitle: 'mia kupres',
  headerSubTitle: 'official website',

  /* manifest.json */
  manifestName: 'miakupres.com - official website',
  manifestShortName: 'miakupres.com', // max 12 characters
  manifestStartUrl: '/index.html',
  manifestBackgroundColor: 'white',
  manifestThemeColor: '#666',
  manifestDisplay: 'standalone',

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: '',

  // social
  authorSocialLinks: [
    {name: 'github', url: 'https://github.com/joekupres'},
    {name: 'twitter', url: 'https://twitter.com/miakupres'},
    {name: 'facebook', url: 'http://facebook.com/miakupres'},
  ],
}
